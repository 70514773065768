import i18n from 'i18next';
import i18nBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { LocalHelper } from './Helpers/LocalHelper';
import { HttpHelper } from './Helpers/HttpHelper';

i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'nl',
    lng: LocalHelper.GetLanguageKey(),
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: HttpHelper.GetUrlBaseApi() + "api/language/{{lng}}",
    },
  });

export default i18n;
