import React, { useEffect, useState } from 'react';
import { authService, serviceBundler } from '../../App';
import { Avatar, Button, Col, Layout, Row, Space, theme } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { UserOutlined } from '@ant-design/icons';

import style from './ProfilePage.module.css';
import { useNavigate } from 'react-router-dom';
import { UserModel } from '../../Models/Auth/UserModel';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography/Typography';
import HeaderBar from '../../Components/HeaderBar/HeaderBar';

const { useToken } = theme;

const ProfilePage = () => {
  const { t, i18n } = useTranslation();
  const { token } = useToken();

  let navigate = useNavigate();

  const [user, setUser] = useState<UserModel>();

  useEffect(() => {
    var currentMail = authService.GetCurrentEmail();
    serviceBundler.userService.GetFetchStatus().subscribe((value) => {
      if (!value) {
        return;
      }
      if (currentMail != null) {
        RetrieveUser(currentMail);
      }
    });
  }, [0]);

  const RetrieveUser = async (currentMail: string) => {
    var currentuser = await serviceBundler.userService.GetUserByEmail(
      currentMail,
    );
    setUser(currentuser);
  };

  const BackToHome = () => {
    navigate('/');
  };

  const Logout = () => {
    authService.Logout();
    BackToHome();
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
        <HeaderBar></HeaderBar>
        <Content
          style={{ backgroundColor: token.colorPrimaryBg }}
          className={style.contentBlock}
        >
          <div className={style.collapseBlock}>
            <Avatar size={128} icon={<UserOutlined />} />

            <div className={style.contentText}>
              <Typography>Welkom {user?.name}</Typography>

              <span className={style.dotColor}></span>
            </div>

            <Button className={style.btnLogout} onClick={() => Logout()}>
              {t('logout')}
            </Button>
          </div>
        </Content>
      </Layout>
    </Space>
  );
};

export default ProfilePage;
