import { Coordinates } from "./Coordinates"
import { PlantColors } from "./PlantColors";

export class DetectionSetting {
    public id!: string

    public nameSetting: string = "";
    public coordinatesFrame: Coordinates = new Coordinates();
    public coordinatesPot: Coordinates = new Coordinates();
    public livingPlantColors: Array<PlantColors> = new Array<PlantColors>();
    public deadPlantColors: Array<PlantColors> = new  Array<PlantColors>();
}
