import { Layout, Menu, MenuProps, theme } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';

import {
  BuildOutlined,
  SlidersOutlined,
  AreaChartOutlined,
  CameraOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SortingMenuTypes } from './Enums/SortingMenuTypes';
import { serviceBundler } from '../../App';

import style from './CenterBlock.module.css';
import { CustomDonut } from '../CustomDonut/CustomDonut';
import { CustomProgress } from '../CustomProgress/CustomProgress';

const { useToken } = theme;

export const CenterBlock = (props: {
  actualTab: SortingMenuTypes;
  content: any;
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { token } = useToken();

  const [currentTab, setCurrentTab] = useState<SortingMenuTypes>(
    SortingMenuTypes.DetectionSettingsTab,
  );

  useEffect(() => {
    setCurrentTab(serviceBundler.tabService.GetCurrentTab());
  }, []);

  let labels: { type: SortingMenuTypes; name: string }[] = [
    { type: SortingMenuTypes.CameraSettingsTab, name: t('camsettings') },
    { type: SortingMenuTypes.DetectionSettingsTab, name: t('detectionsettings') },
    { type: SortingMenuTypes.SortingSettingsTab, name: t('sortingsettings') },
    { type: SortingMenuTypes.ProductionTab, name: t('production') },
  ];

  const menuItems: MenuProps['items'] = [
    CameraOutlined,
    BuildOutlined,
    SlidersOutlined,
    AreaChartOutlined,
  ].map((icon, index) => {
    return {
      key: labels[index].type,
      icon: React.createElement(icon),
      label: labels[index].name,
    };
  });

  const menuClicked = (menuItem: any) => {
    serviceBundler.tabService.SetCurrentTab(menuItem.key);
    NavigateToMenuItem(SortingMenuTypes[menuItem.key]);
  };

  const NavigateToMenuItem = (name: string) => {
    navigate(`/sorting/${name}`, {
      state: {},
    });
  };

  return (
    <Content style={{ padding: '0 50px' }} className={style.layoutStyle}>
      <Layout
        style={{
          padding: '24px 0',
          background: token.colorBgContainer,
          overflowX: 'scroll',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Sider
            style={{
              background: token.colorBgContainer,
              borderRight: '8px solid #F0F0F0'

            }}
            width={250}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={[props.actualTab.toString()]}
              defaultOpenKeys={[props.actualTab.toString()]}
              items={menuItems}
              onClick={menuClicked}
            />
            <div
              style={{
                width: '80%',
                height: '160px',
                position: 'relative',
                marginTop: 50,
              }}
            >
              <CustomDonut />
              <div
                style={{
                  width: 180,
                  height: '80px',
                }}
              >
                <CustomProgress />
              </div>
            </div>
          </Sider>
          <Content>
            <div
              style={{
                padding: '0 24px',
                minHeight: '400',
                overflowY: 'auto',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
              className={style.contentStyle}
            >
              {props.content}
            </div>
          </Content>
        </div>
      </Layout>
    </Content>
  );
};
