import { Camera } from '../Models/CameraSettings/Camera';
import { HttpHelper } from './HttpHelper';

export abstract class CameraSettingHelper {
  private static baseUrl =
    HttpHelper.GetUrlBaseApi() + 'api/' + 'CameraSetting';

  public static async GetCameraSettings() {
    var urlForRequest = this.baseUrl;
    return await HttpHelper.GetRequestForApi<Camera[]>(urlForRequest);
  }

  public static async AddItem(setting: Camera) {
    var urlForRequest = this.baseUrl;
    return await HttpHelper.PostRequestForApi<Camera>(
      urlForRequest,
      setting,
    );
  }

  public static async UpdateItem(setting: Camera) {
    var urlForRequest = this.baseUrl + '/' + setting.id;
    return await HttpHelper.UpdateRequestForApi<Camera>(
      urlForRequest,
      setting,
    );
  }

  public static async DeleteItem(setting: Camera) {
    var urlForRequest = this.baseUrl + '/' + setting.id;
    return await HttpHelper.DeleteRequestForApi<Camera>(urlForRequest);
  }
}
