import React, { useState } from 'react';
import { Table, ConfigProvider, Checkbox } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { TableRowSelection } from 'antd/es/table/interface';

import {
  LoadingOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

interface DataType {
  key: React.Key;
  prop: string;
  sort1max: string;
  sort2min: string;
  sort2max: string;
  sort3min: string;
  sort3max: string;
  sort4min: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Eigenschap',
    dataIndex: 'prop',
    key: 'prop',
  },
  {
    title: 'Uitkomst',
    dataIndex: 'sort1max',
    key: 'sort1max',
  },
];

const propNames = [
  'Hoogte (mm)',
  'Volume totaal (%)',
  'Breedte (mm)',
  'Gemiddelde breedte (mm)',
  'Volume boven pot (%)',
  'Aantal pixels (1)',
  'Aantal pixels dood (1)',
];

const valuesTable = [['20'], ['50'], ['10'], ['10'], ['50'], ['50'], ['50']];

const data: DataType[] = [];
for (let i = 0; i < propNames.length; i++) {
  data.push({
    key: i,
    prop: propNames[i],
    sort1max: valuesTable[i][0],
    sort2min: valuesTable[i][1],
    sort2max: valuesTable[i][2],
    sort3min: valuesTable[i][3],
    sort3max: valuesTable[i][4],
    sort4min: valuesTable[i][5],
  });
}

export const ResultDetectionTableFront = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#a0ad03',
        },
      }}
    >
      <Table
        style={{ width: '300px' }}
        columns={columns}
        dataSource={data}
        bordered
        size="small"
        pagination={false}
      />
    </ConfigProvider>
  );
};
