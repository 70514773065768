import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Gauge } from '@ant-design/plots';

export const CustomProgress = () => {
  const config = {
    percent: 0.75,
    range: {
      color: 'l(0) 0:#ccde07 1:#C1D202',
    },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: {
      pointer: {
        style: {
          visibility: 'hidden',

          stroke: '#FFFFFF',
        },
      },
      pin: {
        style: {
          visibility: 'hidden',
          stroke: '#FFFFFF',

        },
      },
    },

    statistic: {
      title: {
        offsetY: -16,
        style: {
          fontSize: '12px',
          lineHeight: '24px',
          color: '#4B535E',
        },
        formatter: () => '70%',
      },
      content: {
        style: {
          fontSize: '10px',
          lineHeight: '24px',
          color: '#4B535E',
        },
        formatter: () => 'Snelheid',
      },
    },
  };
  return <Gauge {...config} />;
};

