import { LocalHelper } from '../Helpers/LocalHelper';
import { fakeAuthProvider } from '../auth';

export class AuthService {
  private currentEmail!: string | null;
  private token: string = '';

  public SafeToken(token: string, expiresIn: string) {
    LocalHelper.SafeValueWithKey('access_token', token);
    LocalHelper.SafeValueWithKey('expires_at', expiresIn);
    this.token = token;
  }

  public GetToken(): string {
    var tempToken = LocalHelper.GetValueByKey('access_token');
    if (tempToken) {
      return tempToken;
    }
    return '';
  }

  public IsLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    if (authToken == null) {
      return false;
    }
    return new Date() < new Date(this.GetExpiration());
  }

  private GetExpiration = () => {
    const expiration = localStorage.getItem('expires_at');
    if (expiration == undefined) {
      return new Date();
    }
    return expiration;
  };

  public SetLoggedIn(value: boolean) {
    if (!value){
      this.Logout();
    }
    fakeAuthProvider.isAuthenticated = value;
  }

  public Logout() {
    LocalHelper.RemoveKey('access_token');
    LocalHelper.RemoveKey('expires_at');
    this.SetCurrentEmail(null);
  }

  public GetCurrentEmail() {
    return LocalHelper.GetValueByKey('access_token');
  }

  public SetCurrentEmail(email: string | null) {
    this.currentEmail = email;
    if (email) {
      LocalHelper.SafeValueWithKey('current_email', email);
    }
    else{
        LocalHelper.RemoveKey('current_email');
    }
  }
}
