import { ThemeConfig, theme } from 'antd';
import { BehaviorSubject } from 'rxjs';

export class ThemeService {
  public FetchingReadySubject: BehaviorSubject<boolean>;

  public IsDarkMode: boolean = false;

  public config: ThemeConfig = {
    token: {
      colorPrimary: '#C1D202',
      colorPrimaryBg: '#FFFFFF',
    },
  };

  constructor() {
    this.FetchingReadySubject = new BehaviorSubject<boolean>(false);
  }

  public GetConfig() {
    return this.config;
  }

  public ChangeTheme(IsDarkMode: boolean) {
    this.FetchingReadySubject.next(IsDarkMode)

    this.IsDarkMode = !this.IsDarkMode;
  }
}
