import { Link as ReactLink, useNavigate, useParams } from 'react-router-dom';
import { Space, Layout, Form, theme, Image, Button, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';

import { SaveOutlined, WarningOutlined } from '@ant-design/icons';

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderBar from '../../Components/HeaderBar/HeaderBar';
import { CenterBlock } from '../../Components/Sorting/CenterBlock';
import { SortingMenuTypes } from '../../Components/Sorting/Enums/SortingMenuTypes';
import { serviceBundler } from '../../App';
import { Camera } from '../../Models/CameraSettings/Camera';
import Title from 'antd/es/typography/Title';
import { ResultDetectionTableFront } from '../../Components/Tables/ResultDetectionTable';
import { CustomSelector } from '../../Components/Selectors/CustomSelector';
import { SortingLanesTable } from '../../Components/Tables/SortingLanesTable';

import style from './SortingSettingsPage.module.css';
import { SortingSetting } from '../../Models/SortingSettings/SortingSetting';

const { useToken } = theme;

const SortingSettingsPage = () => {
  const { SettingsTab } = useParams();
  const { i18n, t } = useTranslation();
  const { token } = useToken();
  let navigate = useNavigate();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(true);

  const [cameraSettings, setCameraSettings] = useState<Camera[]>([]);

  const [sortingSettings, setSortingSettings] = useState<SortingSetting[]>([]);
  const [sortingSetting, setSortingSetting] = useState<SortingSetting>();
  const [key, setKey] = useState(0);

  const [countRowsValid, setCountRowsValid] = useState(0);
  const [dataSaving, setDataSaving] = useState(false);
  const [dataSaved, setDataSaved] = useState(true);

  const safeTableRef = useRef<any>();

  useEffect(() => {
    console.log('created');
    serviceBundler.tabService.SetCurrentTab(
      SortingMenuTypes.SortingSettingsTab,
    );

    if (loading) {
      console.log('loading');

      FetchItems();
    } else {
      console.log('createddd');

      var currentDetectionSetting =
        serviceBundler.detectionSettingService.GetCurrentSetting();
      if (currentDetectionSetting) {
        if (currentDetectionSetting != null) {
          var foundItem = sortingSettings.find(
            (x) => x.idDetectionSetting == currentDetectionSetting!.id,
          );
          setSortingSetting(foundItem);
          setKey(Math.random());
        }
      }
    }
  }, [loading]);

  useEffect(() => {}, [dataSaved]);

  const FetchItems = async () => {
    var fetchedCameraSettings =
      await serviceBundler.cameraSettingService.GetCameraSettings();
    setCameraSettings(fetchedCameraSettings);

    var fetchedSortingSettings =
      await serviceBundler.sortingSettingService.GetSortingSettings();
    setSortingSettings(fetchedSortingSettings);
    setLoading(false);
  };

  const AddConfig = () => {
    // setIsModalOpen(true);
  };

  const SafeData = () => {
    const node = safeTableRef.current;
    if (node) {
      node.SafeData();
    }
  };

  const DataChangeTrigger = () => {
    serviceBundler.sortingSettingService.timesValidated = 0;
    setDataSaved(false);
  };

  const SafeDataCallback = (updatedSorting: SortingSetting) => {
    var newData = [...sortingSettings];
    var item = newData.find((x) => x.id == updatedSorting.id);
    if (item) {
      item = updatedSorting;
      setSortingSettings(newData);
    }

    setSortingSetting(updatedSorting);

    serviceBundler.sortingSettingService.UpdateSortingSetting(updatedSorting);
    setDataSaved(true);
  };

  const SafeDataBusyCallback = (saving: boolean) => {
    console.log(saving);
    setDataSaved(saving);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
        <HeaderBar></HeaderBar>
        <Content className={style.contentBlock}>
          <CenterBlock
            actualTab={SortingMenuTypes.SortingSettingsTab}
            content={
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'row', minHeight: 340 }}>
                      {cameraSettings.map((cam) => (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: 0,
                            marginRight: 30,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Title level={4}>{cam.name}</Title>
                          </div>
                          <div style={{ marginTop: 20 }}>
                            <img
                              src={cam.linkToHost}
                              style={{
                                cursor: 'pointer',
                                minWidth: 200,
                                maxWidth: 300,
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <Tooltip
                        open={sortingSetting ? false : undefined}
                        title={t('selectsetting')}
                      >
                        <Button
                          disabled={sortingSetting ? false : true}
                          style={{
                            width: 'calc(100% - 30px)',
                            marginRight: 20,
                          }}
                          className={`${
                            sortingSetting
                              ? style.btnDisabled
                              : style.btnDisabled
                          }`}
                        >
                          {t('calculate')}
                        </Button>
                      </Tooltip>
                    </div>
                  </div>

                  <div
                    style={{ marginTop: 0, marginLeft: 20, marginRight: 20 }}
                  >
                    <Title style={{ marginBottom: 30 }} level={4}>
                      {t('results')}
                    </Title>

                    <ResultDetectionTableFront />
                  </div>
                  <div style={{ width: 'fit-content' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 25,
                        marginTop: 0,
                      }}
                    >
                      <div>
                        <Title level={4}>{t('setup')}</Title>
                      </div>
                      <div>
                        <Button
                          style={{ marginTop: 20 }}
                          type={dataSaved ? 'primary' : 'primary'}
                          disabled={dataSaved ? true : false}
                          icon={<SaveOutlined />}
                          loading={dataSaving}
                          onClick={SafeData}
                        ></Button>
                      </div>
                    </div>
                    <SortingLanesTable
                      ref={safeTableRef}
                      data={sortingSetting}
                      dataChangeTrigger={DataChangeTrigger}
                      safeDataBusy={SafeDataBusyCallback}
                      safeData={SafeDataCallback}
                    />
                  </div>
                </div>
              </div>
            }
          ></CenterBlock>
        </Content>
      </Layout>
    </Space>
  );
};

export default SortingSettingsPage;
