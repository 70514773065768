import { Button, Form, FormInstance, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { Language } from '../../Models/Language/Language';
import { useTranslation } from 'react-i18next';
import { RemoveButton } from '../Buttons/RemoveButton';

export function AddLanguageConfig(props: {
  allData?: Language[];
  data?: Language;
  open: boolean;
  onCancel: Function;
  onSafe: Function;
  onRemove: Function;
  onEdit: boolean;
}) {
  const [form] = Form.useForm();

  const { t, i18n } = useTranslation();

  const [removeItem, setRemoveItem] = useState(false);
  const [language, setLanguage] = useState<Language>();

  useEffect(() => {
    form.resetFields();
    setLanguage(props.data);
    setRemoveItem(false);
  }, [form, props.data, language]);

  const handleCancel = () => {
    props.onCancel();
  };

  const onFinishForm = (values: Language) => {
    if (props.data?.id !== undefined) {
      values.id = props.data?.id;
    }

    if (removeItem) {
      props.onRemove(values);
    } else {
      var rightSetting = props.allData?.find((x) => x.id == values.id);
      if (rightSetting != undefined) {
        rightSetting.languageKey = values.languageKey;
        rightSetting.languageName = values.languageName;
      } else {
        rightSetting = values;
      }
      props.onSafe(rightSetting);
    }
  };

  const onFinishFormFailed = (errorInfo: any) => {};

  const checkLabelExist = (_: any, value: string) => {
    var foundItem = props.allData?.find((x) => x.languageKey === value);
    if (foundItem !== undefined) {
      if (foundItem.languageKey === props.data?.languageKey) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Dit label bestaat al'));
    }
    return Promise.resolve();
  };

  return (
    <Modal
      title={t('addalanguage')}
      open={props.open}
      onOk={() => form.submit()}
      okText="Opslaan"
      cancelText="Annuleren"
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Annuleer
        </Button>,
        <>
          {language?.id !== undefined && (
            <RemoveButton
              disabled={false}
              textBtn={t('remove')}
              bindedFunction={setRemoveItem}
              bindedForm={form}
            ></RemoveButton>
          )}
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            Opslaan
          </Button>
        </>,
      ]}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={props.data}
        onFinish={onFinishForm}
        onFinishFailed={onFinishFormFailed}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label="Taal sleutel"
          name="languageKey"
          key="languageKey"
          rules={[
            {
              required: true,
              message: 'Deze sleutel is vereist',
            },
            { validator: checkLabelExist },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Taal naam"
          name="languageName"
          key="languageName"
          rules={[{ required: true, message: 'Vul een naam in' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
