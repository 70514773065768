import { useNavigate, useParams } from 'react-router-dom';
import { Space, Layout, Form, Button, Image, Checkbox, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import {
  SaveOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import style from './DetectionSettingsPage.module.css';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderBar from '../../Components/HeaderBar/HeaderBar';
import { CenterBlock } from '../../Components/Sorting/CenterBlock';
import { SortingMenuTypes } from '../../Components/Sorting/Enums/SortingMenuTypes';
import { serviceBundler, webSocketService } from '../../App';
import CustomDrawer from '../../Components/CustomDrawer/CustomDrawer';
import Title from 'antd/es/typography/Title';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CoordinatesTable } from '../../Components/Tables/CoordinatesTable';
import { CustomSelector } from '../../Components/Selectors/CustomSelector';
import { AddDetectionSettingModal } from './AddDetectionSettingModal';
import { DetectionSetting } from '../../Models/DetectionSettings/DetectionSetting';
import { NewDetectionSetting } from './NewDetectionSetting';
import { Coordinates } from '../../Models/DetectionSettings/Coordinates';
import { AddCameraModal } from '../CameraSettings/AddCameraModal';
import { Camera } from '../../Models/CameraSettings/Camera';
import { PlantColors } from '../../Models/DetectionSettings/PlantColors';
import { SortingSetting } from '../../Models/SortingSettings/SortingSetting';
import { SelectDetectionSetting } from '../../Models/WebSocket/SelectDetectionSetting';

export const DetectionSettingsPage = () => {
  const { SettingsTab } = useParams();
  const { i18n, t } = useTranslation();
  let navigate = useNavigate();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(true);

  const [cameraSettings, setCameraSettings] = useState<Camera[]>([]);

  const [detectionSettings, setDetectionSettings] = useState<
    DetectionSetting[]
  >([]);
  const [detectionSetting, setDetectionSetting] = useState<DetectionSetting>();
  const [currentConfig, setCurrentConfig] = useState<NewDetectionSetting>();
  const [key, setKey] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModelEditMode, setModelEditMode] = useState(false);
  const [dataSaved, setDataSaved] = useState(true);

  const optionsPlant = [
    { label: t('green'), value: PlantColors.Green },
    { label: t('yellow'), value: PlantColors.Yellow },
    { label: t('red'), value: PlantColors.Red },
    { label: t('black'), value: PlantColors.Black },
    { label: t('orange'), value: PlantColors.Orange },
    { label: t('brown'), value: PlantColors.Brown },
  ];

  const optionsDead = [
    { label: t('orange'), value: PlantColors.Orange },
    { label: t('brown'), value: PlantColors.Brown },
  ];

  useEffect(() => {
    serviceBundler.tabService.SetCurrentTab(
      SortingMenuTypes.DetectionSettingsTab,
    );

    if (loading) {
      FetchItems();
    } else {
      var setting = serviceBundler.detectionSettingService.GetCurrentSetting();
      if (setting) {
        setDetectionSetting(setting);
        setKey(Math.random());
      }
    }
  }, [loading]);

  const FetchItems = async () => {
    var fetchedCameraSettings =
      await serviceBundler.cameraSettingService.GetCameraSettings();

    setCameraSettings(fetchedCameraSettings);
    var fetchedDetectionSettings =
      await serviceBundler.detectionSettingService.GetDetectionSettings();
    setDetectionSettings(fetchedDetectionSettings);
    setLoading(false);
  };

  const onChangePlant = (checkedValues: CheckboxValueType[]) => {
    if (detectionSetting) {
      detectionSetting.livingPlantColors = checkedValues.map((value) => {
        return Number(value);
      });
      setDataSaved(false);
    }
  };

  const onChangeDead = (checkedValues: CheckboxValueType[]) => {
    if (detectionSetting) {
      detectionSetting.deadPlantColors = checkedValues.map((x) => {
        return Number(x);
      });
      setDataSaved(false);
    }
  };

  const AddConfig = () => {
    var newConfig = new NewDetectionSetting();
    setCurrentConfig(newConfig);
    setDetectionSetting(undefined);
    setModelEditMode(false);
    setIsModalOpen(true);
  };

  const EditConfig = () => {
    var newConfig = new NewDetectionSetting();

    if (detectionSetting?.nameSetting) {
      newConfig.nameSetting = detectionSetting?.nameSetting;
      setCurrentConfig(newConfig);
    }

    setDetectionSetting(detectionSetting);
    setModelEditMode(true);
    setIsModalOpen(true);
  };

  const CreateCameraConfig = async (data: NewDetectionSetting) => {
    setIsModalOpen(false);

    if (isModelEditMode) {
      return UpdateCameraConfig(data);
    }

    var newConfig = new DetectionSetting();
    if (data.takeFrom != undefined) {
      var settingTakeOver = detectionSettings.find(
        (x) => x.nameSetting == data.takeFrom,
      );
      if (settingTakeOver) {
        newConfig = Object.assign({}, settingTakeOver);
      }
    }

    newConfig.id = '';
    newConfig.nameSetting = data.nameSetting;

    var createdSetting =
      await serviceBundler.detectionSettingService.AddDetectionSetting(
        newConfig,
      );

    //create detection setting
    var newSortingSetting = new SortingSetting();
    newSortingSetting.idDetectionSetting = createdSetting.id;
    serviceBundler.sortingSettingService.AddSortingSetting(newSortingSetting);

    serviceBundler.detectionSettingService.SetCurrentSetting(createdSetting);
    setDetectionSetting(createdSetting);
    setCurrentConfig(data);
    setKey(Math.random());
  };

  const UpdateCameraConfig = (data: NewDetectionSetting) => {
    if (detectionSetting) {
      detectionSetting.nameSetting = data.nameSetting;
      serviceBundler.detectionSettingService.UpdateDetectionSetting(
        detectionSetting,
      );
      setDetectionSetting(detectionSetting);
      setCurrentConfig(data);
      setIsModalOpen(false);
    }
  };

  const RemoveConfig = async () => {
    if (detectionSetting) {
      var cameras =
        serviceBundler.detectionSettingService.RemoveDetectionSetting(
          detectionSetting,
        );
      setDetectionSettings(cameras);

      var allSettings =
        await serviceBundler.sortingSettingService.GetSortingSettings();
      var foundSetting = allSettings.find(
        (x) => x.idDetectionSetting == detectionSetting.id,
      );
      if (foundSetting) {
        serviceBundler.sortingSettingService.RemoveSortingSetting(foundSetting);
      }
    }

    setModelEditMode(false);
    setIsModalOpen(false);

    setDetectionSetting(undefined);
    setCurrentConfig(undefined);
  };

  const ChangeCameraSetting = (nameConfig: string) => {
    var foundSetting = detectionSettings.find(
      (x) => x.nameSetting == nameConfig,
    );
    if (foundSetting) {
      serviceBundler.detectionSettingService.SetCurrentSetting(foundSetting);
      webSocketService.sendMessage(new SelectDetectionSetting(foundSetting))
      setDetectionSetting(foundSetting);
      setKey(Math.random());
    }
  };

  const SafeData = () => {
    if (detectionSetting) {
      serviceBundler.detectionSettingService.UpdateDetectionSetting(
        detectionSetting,
      );
      setDataSaved(true);
    }
  };

  const ChangeCoordinatesFrame = (values: Array<number>) => {
    var updatedConfig = Object.assign({}, detectionSetting);
    updatedConfig.coordinatesFrame = ChangeCoordinatesTable(values);
    setDetectionSetting(updatedConfig);
    setDataSaved(false);
  };

  const ChangeCoordinatesPot = (values: Array<number>) => {
    var updatedConfig = Object.assign({}, detectionSetting);
    updatedConfig.coordinatesPot = ChangeCoordinatesTable(values);
    setDetectionSetting(updatedConfig);
    setDataSaved(false);
  };

  const ChangeCoordinatesTable = (values: Array<number>): Coordinates => {
    var newCoordinates = new Coordinates();
    newCoordinates.startX = values[0];
    newCoordinates.startY = values[1];
    newCoordinates.width = values[2];
    newCoordinates.height = values[3];
    return newCoordinates;
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
        <HeaderBar></HeaderBar>
        <Content className={style.contentBlock}>
          <CenterBlock
            actualTab={SortingMenuTypes.DetectionSettingsTab}
            content={
              <div>
                <div>
                  <AddDetectionSettingModal
                    open={isModalOpen}
                    existingConfigs={detectionSettings}
                    currentConfig={currentConfig}
                    onCancel={() => setIsModalOpen(false)}
                    onCreate={CreateCameraConfig}
                    onRemove={RemoveConfig}
                    onEdit={isModelEditMode}
                  ></AddDetectionSettingModal>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {cameraSettings.map((cam) => (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: 0,
                            marginRight: 30,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Title level={4}>{cam.name}</Title>
                          </div>
                          <div style={{ marginTop: 20 }}>
                            <CustomDrawer />
                          </div>
                        </div>
                      ))}
                    </div>

                    <div style={{ marginTop: 20 }}>
                      <Tooltip
                        open={detectionSetting ? false : undefined}
                        title={t('selectsetting')}
                      >
                        <Button
                          disabled={detectionSetting ? false : true}
                          style={{
                            width: 'calc(100% - 30px)',
                            marginRight: 20,
                          }}
                          className={`${
                            detectionSetting
                              ? style.btnDisabled
                              : style.btnDisabled
                          }`}
                        >
                          {t('calculate')}
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: 20,
                    }}
                  >
                    <Title level={4}>{t('boxes')}</Title>

                    <div style={{ marginTop: 20 }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginTop: 20,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Title level={5}>{t('frame')}</Title>
                          <Tooltip title={t('frametooltip')}>
                            <Button
                              style={{ marginTop: 23 }}
                              ghost
                              icon={<QuestionCircleOutlined />}
                            ></Button>
                          </Tooltip>
                        </div>
                        <Button
                          style={{ marginTop: 23 }}
                          icon={<EditOutlined />}
                        ></Button>
                      </div>
                      <div style={{ width: 290, height: 100 }}>
                        {detectionSetting && (
                          <CoordinatesTable
                            key={key}
                            initValues={detectionSetting.coordinatesFrame}
                            safeValues={ChangeCoordinatesFrame}
                          />
                        )}
                      </div>
                    </div>
                    <div style={{ marginTop: 17 }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Title level={5}>{t('pot')}</Title>
                          <Tooltip title={t('pottooltip')}>
                            <Button
                              style={{ marginTop: 24 }}
                              ghost
                              icon={<QuestionCircleOutlined />}
                            ></Button>
                          </Tooltip>
                        </div>

                        <Button
                          style={{ marginTop: 23 }}
                          icon={<EditOutlined />}
                        ></Button>
                      </div>
                      <div style={{ width: 290 }}>
                        {detectionSetting && (
                          <CoordinatesTable
                            key={key}
                            initValues={detectionSetting.coordinatesPot}
                            safeValues={ChangeCoordinatesPot}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: 30,
                    }}
                  >
                    <Title level={4}>{t('colourpallete')}</Title>
                    <div style={{ marginTop: 50 }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          minWidth: 150,
                        }}
                      >
                        <Title level={5}>{t('plant')}</Title>
                        <Tooltip title={t('plantquestion')}>
                          <Button
                            style={{ marginTop: 23 }}
                            ghost
                            icon={<QuestionCircleOutlined />}
                          ></Button>
                        </Tooltip>
                      </div>
                      <div>
                        <Checkbox.Group
                          key={key}
                          style={{ flexDirection: 'column' }}
                          options={optionsPlant}
                          defaultValue={detectionSetting?.livingPlantColors}
                          onChange={onChangePlant}
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Title level={5}>{t('dead')}</Title>
                        <Tooltip title={t('deadquestion')}>
                          <Button
                            style={{ marginTop: 25 }}
                            ghost
                            icon={<QuestionCircleOutlined />}
                          ></Button>
                        </Tooltip>
                      </div>
                      <Checkbox.Group
                        key={key}
                        style={{ flexDirection: 'column' }}
                        options={optionsDead}
                        defaultValue={detectionSetting?.deadPlantColors}
                        onChange={onChangeDead}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: 60,
                    }}
                  >
                    <div>
                      <div style={{ marginLeft: 120 }}>
                        <CustomSelector
                          key={key}
                          addConfig={AddConfig}
                          value={
                            detectionSetting?.nameSetting
                              ? detectionSetting.nameSetting
                              : null
                          }
                          selectOptions={detectionSettings}
                          changeConfig={ChangeCameraSetting}
                        />
                        <Button
                          type={dataSaved ? 'primary' : 'primary'}
                          disabled={dataSaved ? true : false}
                          icon={<SaveOutlined />}
                          onClick={SafeData}
                        ></Button>
                        <Button
                          style={{ marginLeft: 10 }}
                          type={detectionSetting ? 'primary' : 'primary'}
                          disabled={detectionSetting ? false : true}
                          icon={<EditOutlined />}
                          onClick={EditConfig}
                        ></Button>
                      </div>

                      <div style={{ marginTop: 40 }}>
                        <Title level={4}>{t('results')}</Title>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <div>
                            <Title level={5}>{t('camfront')}</Title>
                            <Image
                              style={{ marginTop: 10 }}
                              width={200}
                              src="https://storage.cloudkwekerijbloemendaal.com/Images/1.png"
                            />
                          </div>
                          <div style={{ marginLeft: 20 }}>
                            <Title level={5}>{t('camtop')}</Title>
                            <Image
                              style={{ marginTop: 10 }}
                              width={200}
                              src="https://storage.cloudkwekerijbloemendaal.com/Images/1.png"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          ></CenterBlock>
        </Content>
      </Layout>
    </Space>
  );
};
