export class Coordinates {
  public startX!: number;
  public startY!: number;
  public width!: number;
  public height!: number;

  constructor(
    startX: number = 20,
    startY: number = 20,
    width: number = 20,
    height: number = 20,
  ) {
    this.startX = startX;
    this.startY = startY;
    this.width = width;
    this.height = height;
  }
}
