import { UserModel } from '../Models/Auth/UserModel';
import { HttpHelper } from './HttpHelper';

export abstract class UserHelper {
  private static baseUrl = HttpHelper.GetUrlBaseApi() + 'api/' + 'User';

  public static async GetUsers(token: string) {
    var urlForRequest = this.baseUrl;
    return await HttpHelper.GetRequestForApi<UserModel[]>(urlForRequest, token);
  }
}
