import { BehaviorSubject, Observable } from 'rxjs';
import { Camera } from '../Models/CameraSettings/Camera';
import { CameraSettingHelper } from '../Helpers/CameraSettingHelper';

export class CameraSettingService {
  public FetchingReadySubject: BehaviorSubject<boolean>;
  public FetchingReady!: boolean;

  public cameraSettings!: Camera[];

  constructor() {
    this.FetchingReadySubject = new BehaviorSubject<boolean>(false);

    this.FetchItems();
  }

  public GetFetchStatus(): Observable<boolean> {
    this.FetchItems();
    return this.FetchingReadySubject;
  }

  private async FetchItems() {
    if (!this.FetchingReady) {
      this.FetchingReady = true;

      this.cameraSettings = await CameraSettingHelper.GetCameraSettings();
      this.FetchingReadySubject.next(true);
    }
  }

  public async GetCameraSettings() {
    if (this.cameraSettings == undefined){
      this.cameraSettings = await CameraSettingHelper.GetCameraSettings();
    }
    return this.cameraSettings;
  }

  public AddCameraSetting = async (newSetting: Camera) => {
    var item = await CameraSettingHelper.AddItem(newSetting)

    if (!this.cameraSettings) {
      this.cameraSettings = [item];
    } else {
      this.cameraSettings.push(item);
    }

    return item;
  };

  public UpdateCameraSetting(item: Camera) {
    var index = this.cameraSettings.findIndex((x) => x.id == item.id);
    if (index >= 0) {
      this.cameraSettings[index] = item;
      CameraSettingHelper.UpdateItem(item);
    }
  }

  public RemoveCameraConfig(item: Camera) {
    var index = this.cameraSettings.findIndex((x) => x.id == item.id);
    if (index >= 0) {
      this.cameraSettings.splice(index, 1)
      CameraSettingHelper.DeleteItem(item);
    }

    return this.cameraSettings;
  }
}
