import {
  Link as ReactLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useAuth } from '../../Providers/AuthProvider';
import {
  Col,
  Space,
  Layout,
  Button,
  Checkbox,
  Form,
  Input,
  Alert,
  Result,
  theme,
  List,
} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';

import { KeyOutlined } from '@ant-design/icons';

import style from './ProductionPage.module.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'antd/es/typography/Link';
import HeaderBar from '../../Components/HeaderBar/HeaderBar';
import { CenterBlock } from '../../Components/Sorting/CenterBlock';
import { SortingMenuTypes } from '../../Components/Sorting/Enums/SortingMenuTypes';
import { serviceBundler } from '../../App';
import { Camera } from '../../Models/CameraSettings/Camera';
import Title from 'antd/es/typography/Title';
import { CustomDonut } from '../../Components/CustomDonut/CustomDonut';
import { ResultsSorting } from '../../Components/Tables/ResultsSorting';
import { CustomMultiLine } from '../../Components/CustomMultiLine/CustomMultiLine';
import { StartStopProduction } from './StartStopProduction';
import { DetectionSetting } from '../../Models/DetectionSettings/DetectionSetting';

const { useToken } = theme;

const ProductionPage = () => {
  const { ProductionTab } = useParams();
  const { i18n, t } = useTranslation();
  const { token } = useToken();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [cameras, setCameras] = useState<Camera[]>([]);

  const [sortingStarted, setSortingStarted] = useState(false);
  const [sortingPaused, setSortingPaused] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    serviceBundler.tabService.SetCurrentTab(SortingMenuTypes.ProductionTab);

    if (loading) {
      FetchItems();
    }
  }, [0]);

  const GetCurrentDetectionSetting = () => {
    return serviceBundler.detectionSettingService.GetCurrentSetting()
  }

  const FetchItems = async () => {
    setLoading(true);
  };

  const StartSorting = () => {
    setIsModalOpen(true);
  };

  const StopSorting = () => {
    setIsModalOpen(true);
  };

  const StartSortingConfirm = () => {
    setSortingStarted(true);
    setIsModalOpen(false);
  };

  const StopSortingConfirm = () => {
    setSortingStarted(false);
    setIsModalOpen(false);
  };

  const RenderTitle = () => {
    if (sortingStarted){
      return 'Stop sortering'
    }
    return 'Start sortering';
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
        <HeaderBar></HeaderBar>
        <Content className={style.contentBlock}>
          <CenterBlock
            actualTab={SortingMenuTypes.ProductionTab}
            content={
              <>
                <div>
                  <StartStopProduction
                    title={RenderTitle()}
                    open={isModalOpen}
                    currentSetting={GetCurrentDetectionSetting()}
                    sortingStarted={sortingStarted}
                    onCancel={() => setIsModalOpen(false)}
                    onStop={() => StopSortingConfirm()}
                    onStart={() => StartSortingConfirm()}
                  ></StartStopProduction>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'hidden',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Title level={4}>Productie</Title>
                    <div
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <Button
                        disabled={sortingStarted}
                        type="primary"
                        onClick={StartSorting}
                      >
                        {t('startsorting')}
                      </Button>
                      <Button
                        onClick={() => setSortingPaused(true)}
                        disabled={!sortingStarted}
                        style={{ marginLeft: 20 }}
                      >
                        {t('pausesorting')}
                      </Button>
                      <Button
                        disabled={!sortingStarted}
                        style={{ marginLeft: 20 }}
                        onClick={StopSorting}
                      >
                        {t('stopsorting')}
                      </Button>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      overflowY: 'hidden',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        marginTop: 50,
                        minWidth: 650,
                      }}
                    >
                      <ResultsSorting />
                    </div>
                    <div style={{ width: 300, marginLeft: 100 }}>
                      <CustomDonut />
                    </div>
                  </div>
                </div>
              </>
            }
          ></CenterBlock>
        </Content>
      </Layout>
    </Space>
  );
};

export default ProductionPage;
