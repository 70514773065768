import { DetectionSetting } from '../Models/DetectionSettings/DetectionSetting';
import { HttpHelper } from './HttpHelper';

export abstract class DetectionSettingHelper {
  private static baseUrl =
    HttpHelper.GetUrlBaseApi() + 'api/' + 'DetectionSetting';

  public static async GetDetectionSettings() {
    var urlForRequest = this.baseUrl;
    return await HttpHelper.GetRequestForApi<DetectionSetting[]>(urlForRequest);
  }

  public static async AddItem(setting: DetectionSetting) {
    var urlForRequest = this.baseUrl;
    return await HttpHelper.PostRequestForApi<DetectionSetting>(
      urlForRequest,
      setting,
    );
  }

  public static async UpdateItem(setting: DetectionSetting) {
    var urlForRequest = this.baseUrl + '/' + setting.id;
    return await HttpHelper.UpdateRequestForApi<DetectionSetting>(
      urlForRequest,
      setting,
    );
  }

  public static async DeleteItem(setting: DetectionSetting) {
    var urlForRequest = this.baseUrl + '/' + setting.id;
    return await HttpHelper.DeleteRequestForApi<DetectionSetting>(urlForRequest);
  }
}
