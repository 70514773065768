import { Button, FormInstance } from 'antd';
import { CSSProperties } from 'react';

export const RemoveButton = (props: {
  disabled: boolean;
  textBtn: string;
  bindedFunction: Function;
  bindedForm: FormInstance;
  customStyle?: CSSProperties;
}) => {
  return (
    <>
      <Button
        style={props.customStyle}
        disabled={props.disabled}
        key="link"
        type="primary"
        onClick={() => {
          props.bindedFunction(true);
          props.bindedForm.submit();
        }}
      >
        {props.textBtn}
      </Button>
    </>
  );
};
