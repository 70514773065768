import { Button, Divider, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { PlusSquareOutlined } from '@ant-design/icons';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { DetectionSetting } from '../../Models/DetectionSettings/DetectionSetting';

export const CustomSelector = (props: {
  addConfig: Function;
  value: string | null;
  selectOptions: Array<DetectionSetting>;
  changeConfig: Function;
}) => {
  const { t, i18n } = useTranslation();

  const GetItems = () => {
    return props.selectOptions.map((x) => {
      return {
        value: x.nameSetting,
        label: x.nameSetting,
      };
    });
  };

  return (
    <Select
      value={props.value}
      showSearch
      style={{
        width: 200,
        marginTop: 25,
        marginRight: 10,
      }}
      placeholder={t('choosetype')}
      filterOption={(input, option) =>
        (option?.label ?? '').toString().includes(input)
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '')
          .toString()
          .toLowerCase()
          .localeCompare((optionB?.label ?? '').toString().toLowerCase())
      }
      onChange={(name) => props.changeConfig(name)}
      options={GetItems()}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Button
              onClick={() => props.addConfig()}
              style={{ marginLeft: 12 }}
              icon={<PlusSquareOutlined />}
            >
              {t('addtype')}
            </Button>
          </Space>
        </>
      )}
    />
  );
};
