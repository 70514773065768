import { WebSocketMessageType } from "./WebSocketMessageType";

export class MessageWebSocket{
    public Type?: WebSocketMessageType;
    public SessionId?: string;

    constructor(){

    }
}
