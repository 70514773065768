import { Header } from 'antd/es/layout/layout';
import { Button, Col, Image, Row, Select, notification, theme } from 'antd';
import imageHeader from '../../Assets/headColor.png';
import { Link, useNavigate } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';

import style from './HeaderBar.module.css';
import { useTranslation } from 'react-i18next';
import { LocalHelper } from '../../Helpers/LocalHelper';
import { Language } from '../../Models/Language/Language';
import { serviceBundler } from '../../App';
import AvatarDropdown from '../AvatarDropDown/AvatarDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

const { useToken } = theme;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const HeaderBar = (props: {}) => {
  let navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { token } = useToken();

  const [api, contextHolder] = notification.useNotification();

  const NavigateToContact = () => {
    navigate('/overandcontact');
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const [usedMobile, setUsedMobile] = useState(false);
  const [banner, setShowBanner] = useState(true);
  const [currentLanguageKey, setCurrentLanguageKey] = useState('nl');
  const [availableLanguages, setAvailableLanguages] = useState<Language[]>([]);
  const [darkTheme, setDarkTheme] = useState<boolean>(false);

  useEffect(() => {
    CheckLanguageKey();
    CheckDarkMode();

    serviceBundler.languageService.GetFetchStatus().subscribe((value) => {
      if (!value) {
        return;
      }
      var settings = serviceBundler.languageService.GetLanguages();
      if (settings !== undefined) {
        setAvailableLanguages(settings);
      }
    });

    if (LocalHelper.GetValueByKey('showplaybanner') == 'false') {
      setShowBanner(false);
    }

    ResizeAvatar();
    function handleResize() {
      ResizeAvatar();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentLanguageKey, darkTheme]);

  const GetCurrentLanguageKey = () => {
    return currentLanguageKey;
  };

  const CheckLanguageKey = () => {
    var key = LocalHelper.GetValueByKey('languageKey');
    if (key != null) {
      setCurrentLanguageKey(key);
    } else {
      setCurrentLanguageKey('nl');
    }
  };

  const CheckDarkMode = () => {
    var key = LocalHelper.GetValueByKey('darkMode');
    if (key != null) {
      if (key == 'true') {
        setDarkTheme(true);
      } else {
        setDarkTheme(false);
      }
    }
    serviceBundler.themeService.ChangeTheme(darkTheme);
  };

  const HideBanner = () => {
    setShowBanner(false);
    LocalHelper.SafeValueWithKey('showplaybanner', 'false');
  };

  const ResizeAvatar = () => {
    setWindowDimensions(getWindowDimensions());

    if (windowDimensions.width > 600) {
      setUsedMobile(false);
    } else {
      setUsedMobile(true);
    }
  };

  const handleChange = (value: any) => {
    LocalHelper.SafeValueWithKey('languageKey', value);
    setCurrentLanguageKey(value);
    i18n.changeLanguage(value);
  };

  const SwitchTheme = () => {
    var newDarkMode = false;
    var key = LocalHelper.GetValueByKey('darkMode');
    if (key != null) {
      if (key == 'true') {
        newDarkMode = false;
      } else {
        newDarkMode = true;
      }
    }

    LocalHelper.SafeValueWithKey('darkMode', newDarkMode.toString());
    serviceBundler.themeService.ChangeTheme(newDarkMode);
    setDarkTheme(newDarkMode);
  };

  const RenderIcon = (value: string) => {
    var classNameFlag = 'fi fi-' + value;

    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classNameFlag} style={{ width: '20px' }}></div>
          <div style={{ marginLeft: '10px' }}>{value.toLocaleUpperCase()}</div>
        </div>
      </>
    );
  };

  const AvailableFlags = () => {
    return availableLanguages.map((xx) => {
      return {
        label: <>{RenderIcon(xx.languageKey)}</>,
        value: xx.languageKey,
      };
    });
  };

  return (
    <Header
      style={{ backgroundColor: token?.colorPrimaryBg }}
      className={style.headerBlock}
    >
      {!usedMobile && (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              height: 90,
            }}
          >
            <div>
              <Link to="/" className={style.headerLogo}>
                <Image
                  className={style.headerImage}
                  preview={false}
                  src={imageHeader}
                />
              </Link>
            </div>
            <div>
              <Link
                to="/"
                style={{
                  backgroundColor: token?.colorPrimaryBg,
                  color: token?.colorPrimaryText,
                }}
                className={style.headerName}
              >
                Kwekerij Bloemendaal
              </Link>
            </div>
            <div>
              <div className={style.headerAvatar}>
                <AvatarDropdown></AvatarDropdown>
                <Button
                  onClick={SwitchTheme}
                  style={{
                    borderStyle: 'none',
                    backgroundColor: '#C1D202',
                    top: 20,
                    width: 44,
                  }}
                >
                  {!darkTheme && (
                    <FontAwesomeIcon icon={faSun} className={style.sunIcon} />
                  )}
                  {darkTheme && (
                    <FontAwesomeIcon icon={faMoon} className={style.sunIcon} />
                  )}
                </Button>
                <div>
                  <Select
                    defaultValue={GetCurrentLanguageKey()}
                    style={{
                      position: 'relative',
                      top: -5,
                      marginLeft: 10,
                      width: 90,
                    }}
                    value={GetCurrentLanguageKey()}
                    bordered={false}
                    options={AvailableFlags()}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {usedMobile && (
        <>
          {banner && (
            <>
              <div
                style={{
                  width: '100%',
                  height: '60px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  position: 'relative',
                  lineHeight: '20px',
                  borderBottom: 'solid',
                }}
              >
                <div>
                  <a href="https://play.google.com/store/apps/details?id=com.kwebloapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    <img
                      className={style.googlePlayButton}
                      alt="Get it on Google Play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    />
                  </a>
                </div>
                <div style={{ paddingLeft: '50px', paddingTop: '10px' }}>
                  Download de app nu in Google Play
                </div>
                <Button
                  onClick={() => HideBanner()}
                  ghost
                  style={{
                    position: 'relative',
                    marginTop: '20px',
                    marginRight: '15px',
                  }}
                  icon={<CloseCircleOutlined />}
                ></Button>
              </div>
            </>
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Link to="/" className={style.headerName} style={{ left: '0px' }}>
              <Image
                className={style.headerImage}
                preview={false}
                src={imageHeader}
              />
            </Link>
            <div className={style.headerAvatar}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <AvatarDropdown></AvatarDropdown>
                <Button
                  style={{ marginTop: 20, borderRadius: 20 }}
                  type="primary"
                  onClick={NavigateToContact}
                >
                  Over & Contact
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Header>
  );
};

export default HeaderBar;
