import { SortingSetting } from '../Models/SortingSettings/SortingSetting';
import { HttpHelper } from './HttpHelper';

export abstract class SortingSettingHelper {
  private static baseUrl =
    HttpHelper.GetUrlBaseApi() + 'api/' + 'SortingSetting';

  public static async GetDetectionSettings() {
    var urlForRequest = this.baseUrl;
    return await HttpHelper.GetRequestForApi<SortingSetting[]>(urlForRequest);
  }

  public static async AddItem(setting: SortingSetting) {
    var urlForRequest = this.baseUrl;
    return await HttpHelper.PostRequestForApi<SortingSetting>(
      urlForRequest,
      setting,
    );
  }

  public static async UpdateItem(setting: SortingSetting) {
    var urlForRequest = this.baseUrl + '/' + setting.id;
    return await HttpHelper.UpdateRequestForApi<SortingSetting>(
      urlForRequest,
      setting,
    );
  }

  public static async DeleteItem(setting: SortingSetting) {
    var urlForRequest = this.baseUrl + '/' + setting.id;
    return await HttpHelper.DeleteRequestForApi<SortingSetting>(urlForRequest);
  }
}
