import { SortingMenuTypes } from '../Components/Sorting/Enums/SortingMenuTypes';

export class TabService {
  public currentTab: SortingMenuTypes = SortingMenuTypes.DetectionSettingsTab;

  public SetCurrentTab(tab: SortingMenuTypes) {
    this.currentTab = tab;
  }

  public GetCurrentTab() {
    return this.currentTab;
  }
}
