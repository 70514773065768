import { Distributions } from "../../Components/Sorting/Enums/Distributions";
import { MeasuringPoints } from "../../Components/Sorting/Enums/MeasuringPoints";

export class SortingSetting {
  id!: string;
  idDetectionSetting!: string;

  selectedDistribution: Array<Distributions> = new Array<Distributions>();
  selectedMeasuringPoints: Array<MeasuringPoints> = Array<MeasuringPoints>();

  settedValues: Array<Array<number>> = new Array<Array<number>>();
}
