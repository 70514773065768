import { Button, Form, Input, Modal, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { DetectionSetting } from '../../Models/DetectionSettings/DetectionSetting';

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

export function StartStopProduction(props: {
  title: string;
  open: boolean;
  currentSetting: DetectionSetting | null;
  sortingStarted: boolean;
  onCancel: Function;
  onStart: Function;
  onStop: Function;
}) {
  const { i18n, t } = useTranslation();

  const [form] = Form.useForm();

  const onFinishForm = () => {
    if (props.sortingStarted) {
      props.onStop();
      return;
    }
    props.onStart();
  };

  const RenderTitle = () => {
    var title = props.title;
    if (props.currentSetting != null) {
      title += ' ' + t('withsetting') + ' "' + props.currentSetting.nameSetting + '" ';
    }
    return title;
  };

  const RenderOkText = () => {
    if (props.sortingStarted) {
      return t('stop');
    }
    return t('start');
  };

  return (
    <Modal
      title={RenderTitle()}
      open={props.open}
      footer={[
        <Button
          style={{ marginRight: 10 }}
          key="back"
          onClick={() => props.onCancel()}
        >
          {t('cancel')}
        </Button>,
        <Tooltip
          open={props.currentSetting ? false : undefined}
          title={t('selectsetting')}
        >
          <Button
            disabled={props.currentSetting ? false : true}
            key="submit"
            type="primary"
            onClick={() => form.submit()}
          >
            {RenderOkText()}
          </Button>
        </Tooltip>,
      ]}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onFinishForm}
      >
        <Form.Item
          label="Naam"
          name="Soort naam"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Start datum" name="Start datum">
          <Input disabled />
        </Form.Item>

        {props.sortingStarted && (
          <Form.Item label="Stop datum" name="Stop datum">
            <Input disabled />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}
