import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  Table,
  ConfigProvider,
  Checkbox,
  Input,
  Form,
  InputNumber,
  FormInstance,
  Button,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { TableRowSelection } from 'antd/es/table/interface';

import { MenuOutlined } from '@ant-design/icons';

import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { CSS } from '@dnd-kit/utilities';
import { SortingDivisions } from '../Sorting/Enums/SortingDivisions';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useTranslation } from 'react-i18next';
import { time } from 'console';
import { SortingSetting } from '../../Models/SortingSettings/SortingSetting';
import { serviceBundler } from '../../App';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const propNames = [
  'Hoogte',
  'Volume totaal',
  'Breedte',
  'Gemiddelde breedte',
  'Volume boven pot',
  'Aantal pixels',
  'Aantal pixels dood',
];

const valuesTable = [
  [20, 20, 25, 25, 30, 35],
  [80, 50, 70, 70, 80, 80],
  [10, 10, 12, 12, 14, 14],
  [5, 10, 11, 11, 12, 12],
  [50, 50, 70, 70, 80, 80],
  [30, 20, 25, 25, 30, 35],
  [40, 50, 70, 70, 80, 80],
];

interface Item {
  key: string;
  prop: string;
  MaxFirstDevision: number;
  MinSecondDevision: number;
  MaxSecondDevision: number;
  MinThirdDevision: number;
  MaxThirdDevision: number;
  MinFourdDevision: number;
}

export const SortingLanesTable = forwardRef(
  (
    props: {
      data?: SortingSetting;
      dataChangeTrigger: Function;
      safeDataBusy: Function;
      safeData: Function;
    },
    ref,
  ) => {
    const { i18n, t } = useTranslation();

    var allFormInstances: (FormInstance<any> | undefined)[] = [];
    for (let i = 0; i < propNames.length; i++) {
      const [form] = Form.useForm();
      allFormInstances.push(form);
    }

    const [sortingSetting, setSortingSetting] = useState<SortingSetting>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [data, setData] = useState<Array<Item>>([]);
    const [rawData, setRawData] = useState<Array<number>[]>([]);

    const [validated, setValidated] = useState(false);

    const [checkedDivions, setCheckedDivions] = useState<React.Key[]>([]);

    useImperativeHandle(ref, () => ({
      SafeData() {
        ValidateAllForms();
      },
    }));

    useEffect(() => {
      setData(data);
      setRawData(valuesTable);

      setSortingSetting(props.data);
      setValidated(false);
    }, [0]);

    useEffect(() => {
      setSortingSetting(props.data);

      if (props.data) {
        serviceBundler.sortingSettingService.SetCount(
          props.data.selectedMeasuringPoints.length,
        );
        PrepereData(props.data.settedValues);
        setSelectedRowKeys(
          props.data.selectedMeasuringPoints.map((x) => {
            return x.toString();
          }),
        );
        setCheckedDivions(
          props.data.selectedDistribution.map((x) => {
            return x.toString();
          }),
        );
      }
      setValidated(false);
    }, [props.data]);

    useEffect(() => {
      serviceBundler.sortingSettingService.GetCountStatus().subscribe((x) => {
        var tmToVal = serviceBundler.sortingSettingService.timesToValidate;

        if (tmToVal == 0) {
          return;
        }
        if (x == tmToVal) {
          setValidated(true);
        }
      });
    }, [0]);

    useEffect(() => {
      if (validated) {
        var newSortingSetting = Object.assign({}, sortingSetting);
        newSortingSetting.settedValues = rawData;
        props.safeData(newSortingSetting);
      }
    }, [validated]);

    const PrepereData = (values: number[][]) => {
      var valuesToUse = values;
      if (valuesToUse.length == 0) {
        valuesToUse = valuesTable;
      }

      setRawData(valuesToUse);

      const newData: Item[] = [];
      for (let i = 0; i < propNames.length; i++) {
        newData.push({
          key: i.toString(),
          prop: propNames[i],
          MaxFirstDevision: valuesToUse[i][0],
          MinSecondDevision: valuesToUse[i][1],
          MaxSecondDevision: valuesToUse[i][2],
          MinThirdDevision: valuesToUse[i][3],
          MaxThirdDevision: valuesToUse[i][4],
          MinFourdDevision: valuesToUse[i][5],
        });
      }

      setData(newData);
    };

    const onSelectChangeProperty = (newSelectedRowKeys: React.Key[]) => {
      serviceBundler.sortingSettingService.SetCount(newSelectedRowKeys.length);
      props.dataChangeTrigger();
      setSelectedRowKeys(newSelectedRowKeys);

      if (sortingSetting) {
        sortingSetting.selectedMeasuringPoints = newSelectedRowKeys.map((x) => {
          return Number(x);
        });
      }
    };

    const CheckLabelExist = (
      sortingDivision: number,
      indexRow: number,
      indexColumn: number,
      alldata: any,
      value: number,
    ) => {
      var newRawData = [...rawData];
      newRawData[indexRow][indexColumn] = value;

      if (indexColumn > 0) {
        if (!checkedDivions[sortingDivision]) {
          return Promise.resolve();
        }

        if (selectedRowKeys.find((x) => x == indexRow)) {
          if (
            rawData[indexRow][indexColumn - 1] >= rawData[indexRow][indexColumn]
          ) {
            props.dataChangeTrigger();
            return Promise.reject(t('fillahighernumber'));
          }
        }
      }

      setValidated(false);
      props.dataChangeTrigger();
      return Promise.resolve();
    };

    const ValidateAllForms = async () => {
      allFormInstances.forEach(async function (formInstance, index) {
        if (selectedRowKeys.find((x) => x == index)) {
          if (!formInstance) {
            return;
          }
          var result = formInstance.validateFields();
          await formInstance
            .validateFields({ validateOnly: true })
            .then((data) => {
              serviceBundler.sortingSettingService.AddCount();
            })
            .catch((err) => {
              console.log('error');
            });
        }
      });
    };

    const onChangeDevison = (index: number, e: CheckboxChangeEvent) => {
      var newDivions = [...checkedDivions];
      const foundIndex = newDivions.indexOf(index.toString(), 0);
      if (foundIndex > -1) {
        if (!e.target.checked) newDivions.splice(index, 1);
      } else {
        if (e.target.checked) {
          newDivions.push(index.toString());
        }
      }

      if (!e.target.checked) {
        newDivions = newDivions.slice(0, index);
      }

      setCheckedDivions(newDivions);
      props.dataChangeTrigger();

      if (sortingSetting) {
        sortingSetting.selectedDistribution = newDivions.map((x) => {
          return Number(x);
        });
      }
    };

    const IsCheckedDistribution = (index: number) => {
      const foundIndex = checkedDivions.indexOf(index.toString(), 0);
      if (foundIndex > -1) {
        return true;
      }
      return false;
    };

    const GetCheckValue = (index: number) => {
      return checkedDivions[index] ? false : true;
    };

    const RenderFormInput = (
      indexRow: number,
      indexColumn: number,
      DevisionType: SortingDivisions,
      text: string,
    ) => {
      return (
        <Form.Item
          initialValue={text}
          name={indexColumn}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Vul het veld in`,
            },
            {
              validator: (alldata, value) => {
                return CheckLabelExist(
                  DevisionType,
                  indexRow,
                  indexColumn,
                  alldata,
                  value,
                );
              },
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
      );
    };

    const columns: ColumnsType<Item> = [
      {
        key: 'sort',
      },
      {
        title: '',
        children: [
          {
            title: 'Eigenschap',
            dataIndex: 'prop',
            key: 'prop',
          },
        ],
      },
      {
        title: (
          <>
            <Checkbox
              checked={IsCheckedDistribution(0)}
              onChange={(e) => onChangeDevison(0, e)}
            >
              Uitval
            </Checkbox>
          </>
        ),
        children: [
          {
            title: 'Maximaal',
            dataIndex: SortingDivisions[SortingDivisions.MaxFirstDevision],
            key: SortingDivisions.MaxFirstDevision,
            render: (text, record, index) => RenderFormInput(index, 0, 0, text),
          },
        ],
      },
      {
        title: (
          <>
            <Checkbox
              disabled={GetCheckValue(0)}
              checked={IsCheckedDistribution(1)}
              onChange={(e) => onChangeDevison(1, e)}
            >
              Sortering 1
            </Checkbox>
          </>
        ),
        children: [
          {
            title: 'Minimaal',
            dataIndex: SortingDivisions[SortingDivisions.MinSecondDevision],
            key: SortingDivisions.MinSecondDevision,
            render: (text, record, index) => RenderFormInput(index, 1, 1, text),
          },
          {
            title: 'Maximaal',
            dataIndex: SortingDivisions[SortingDivisions.MaxSecondDevision],
            key: SortingDivisions.MaxSecondDevision,
            render: (text, record, index) => RenderFormInput(index, 2, 1, text),
          },
        ],
      },
      {
        title: (
          <>
            <Checkbox
              disabled={GetCheckValue(1)}
              checked={IsCheckedDistribution(2)}
              onChange={(e) => onChangeDevison(2, e)}
            >
              Sortering 2
            </Checkbox>
          </>
        ),
        children: [
          {
            title: 'Minimaal',
            dataIndex: SortingDivisions[SortingDivisions.MinThirdDevision],
            key: SortingDivisions.MinThirdDevision,
            render: (text, record, index) => RenderFormInput(index, 3, 2, text),
          },
          {
            title: 'Maximaal',
            dataIndex: SortingDivisions[SortingDivisions.MaxThirdDevision],
            key: SortingDivisions.MaxThirdDevision,
            render: (text, record, index) => RenderFormInput(index, 4, 2, text),
          },
        ],
      },
      {
        title: (
          <>
            <Checkbox
              disabled={GetCheckValue(2)}
              checked={IsCheckedDistribution(3)}
              onChange={(e) => onChangeDevison(3, e)}
            >
              Sortering 3
            </Checkbox>
          </>
        ),
        children: [
          {
            title: 'Minimaal',
            dataIndex: SortingDivisions[SortingDivisions.MinFourdDevision],
            key: SortingDivisions.MinFourdDevision,
            render: (text, record, index) => RenderFormInput(index, 5, 3, text),
          },
        ],
      },
    ];

    const rowSelection: TableRowSelection<Item> = {
      selectedRowKeys,
      onChange: onSelectChangeProperty,
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        Table.SELECTION_NONE,
        {
          key: 'odd',
          text: 'Select Odd Row',
          onSelect: (changeableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            });
            setSelectedRowKeys(newSelectedRowKeys);
          },
        },
        {
          key: 'even',
          text: 'Select Even Row',
          onSelect: (changeableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
              if (index % 2 !== 0) {
                return true;
              }
              return false;
            });
            setSelectedRowKeys(newSelectedRowKeys);
          },
        },
      ],
    };

    const Row = ({ children, ...props }: RowProps) => {
      const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
      } = useSortable({
        id: props['data-row-key'],
      });

      const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Transform.toString(
          transform && { ...transform, scaleY: 1 },
        ),
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
      };

      return (
        <Form
          form={allFormInstances[Number(props['data-row-key'])]}
          component={false}
        >
          <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
              if ((child as React.ReactElement).key === 'sort') {
                return React.cloneElement(child as React.ReactElement, {
                  children: (
                    <MenuOutlined
                      ref={setActivatorNodeRef}
                      style={{ touchAction: 'none', cursor: 'move' }}
                      {...listeners}
                    />
                  ),
                });
              }

              return child;
            })}
          </tr>
        </Form>
      );
    };

    const SafeAllDataAfterDrag = (indexFirst: number, indexSecond: number) => {
      var newData = [...data];
      var tmp = newData[indexSecond];
      newData[indexSecond] = newData[indexFirst];
      newData[indexFirst] = tmp;

      var newDataRaw = [...rawData];
      var tmpRawData = newDataRaw[indexSecond];
      newDataRaw[indexSecond] = newDataRaw[indexFirst];
      newDataRaw[indexFirst] = tmpRawData;
      setRawData(newDataRaw);

      props.dataChangeTrigger();
    };

    const onDragEnd = ({ active, over }: DragEndEvent) => {
      if (active.id !== over?.id) {
        setData((previous) => {
          const activeIndex = previous.findIndex((i) => i.key === active.id);
          const overIndex = previous.findIndex((i) => i.key === over?.id);
          SafeAllDataAfterDrag(activeIndex, overIndex);

          return arrayMove(previous, activeIndex, overIndex);
        });
      }
    };

    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#a0ad03',
          },
        }}
      >
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            items={data.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              components={{
                body: {
                  row: Row,
                },
              }}
              rowSelection={rowSelection}
              style={{ width: '1000px' }}
              columns={columns}
              dataSource={data}
              bordered
              size="small"
              pagination={false}
              rowClassName="editable-row"
            />
          </SortableContext>
        </DndContext>
      </ConfigProvider>
    );
  },
);
