import { BehaviorSubject, Observable } from 'rxjs';
import { Language } from '../Models/Language/Language';
import { LanguageHelper } from '../Helpers/LanguageHelper';

export class LanguageService {
  public FetchingReadySubject: BehaviorSubject<boolean>;
  public FetchingReady!: boolean;

  public languages!: Language[];
  public languagesTranslations!: Language[];

  public currentLanguage!: Language;

  constructor() {
    this.FetchingReadySubject = new BehaviorSubject<boolean>(false);
  }

  private async FetchItems() {
    if (!this.FetchingReady) {
      this.FetchingReady = true;
      this.languages = await LanguageHelper.GetAvailableLanguages();
      this.languagesTranslations = await LanguageHelper.GetLanguages();
      this.FetchingReadySubject.next(true);
    }
  }

  public GetFetchStatus(): Observable<boolean> {
    this.FetchItems();
    return this.FetchingReadySubject;
  }

  // get overview
  public GetLanguages() {
    return this.languages;
  }

  public GetLanguage(item: string) {
    var index = this.languagesTranslations.findIndex(
      (x) => x.languageKey === item,
    );
    return this.languagesTranslations[index];
  }

  public SetCurrentLanguage(item: Language) {
    this.currentLanguage = item;
  }

  public GetCurrentLanguage() {
    return this.currentLanguage;
  }

  // update overview
  public UpdateLanguage(item: Language) {
    var index = this.languages.findIndex((x) => x.id === item.id);
    this.languages[index] = item;
    LanguageHelper.UpdateLanguage(item);
  }

  // add overview
  public async AddLanguage(newItem: Language) {
    var createdItem = await LanguageHelper.AddLanguage(newItem);
    this.languages.push(createdItem);
  }

  // remove overview
  public DeleteLanguage(item: Language) {
    var index = this.languages.findIndex((x) => x.id === item.id);
    this.languages.splice(index, 1);
    LanguageHelper.DeleteLanguage(item);
  }
}
