import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from 'react';
import { Table, ConfigProvider, Form, InputNumber } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { useTranslation } from 'react-i18next';
import { Coordinates } from '../../Models/DetectionSettings/Coordinates';
import { CoordinatesType } from '../../Pages/DetectionSettings/CoordinatesType';

interface DataType {
  key: React.Key;
  startx: number;
  width: number;
  starty: number;
  height: number;
}

export const CoordinatesTable = forwardRef(
  (
    props: {
      initValues: Coordinates;
      safeValues: Function;
    },
    ref,
  ) => {
    const { i18n, t } = useTranslation();

    const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    const [form] = Form.useForm();
    const [coordinates, setCoordinates] = useState<Array<DataType>>([]);

    useImperativeHandle(ref, () => ({
      SetSearchText(text: string) {},
    }));

    useEffect(() => {
      setCoordinates([]);

      AsignValuesFromCoordinates(props.initValues);
      forceUpdate();
    }, [props.initValues]);

    const AsignValuesFromCoordinates = (initValues: Coordinates) => {
      var newCoordinates: DataType = {
        key: Math.random(),
        startx: initValues.startX,
        width: initValues.width,
        starty: initValues.startY,
        height: initValues.height,
      };
      setCoordinates([newCoordinates]);
    };

    const columns: ColumnsType<DataType> = [
      {
        title: t('startx'),
        dataIndex: 'startx',
        key: 'startX',
        render: (text, record, index) =>
          RenderFormInput(CoordinatesType.StartX, text),
      },
      {
        title: t('width'),
        dataIndex: 'width',
        key: 'width',
        render: (text, record, index) =>
          RenderFormInput(CoordinatesType.StartY, text),
      },
      {
        title: t('starty'),
        dataIndex: 'starty',
        key: 'startY',
        render: (text, record, index) =>
          RenderFormInput(CoordinatesType.Width, text),
      },
      {
        title: t('height'),
        dataIndex: 'height',
        key: 'height',
        render: (text, record, index) =>
          RenderFormInput(CoordinatesType.Height, text),
      },
    ];

    const RenderFormInput = (type: CoordinatesType, text: number) => {
      return (
        <Form.Item
          initialValue={text}
          name={type}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Vul het veld in`,
            },
          ]}
        >
          <InputNumber onChange={ChangeInput} min={0} style={{ width: 60 }} />
        </Form.Item>
      );
    };

    const onFinish = (values: Array<number>) => {
      props.safeValues(values);
    };

    const ChangeInput = () => {
      form.submit();
    };

    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#a0ad03',
          },
        }}
      >
        <Form form={form} component={false} onFinish={onFinish}>
          <Table
            columns={columns}
            dataSource={coordinates}
            bordered
            size="small"
            pagination={false}
          />
        </Form>
      </ConfigProvider>
    );
  },
);
