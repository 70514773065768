import { BehaviorSubject, Observable } from 'rxjs';
import { SortingSetting } from '../Models/SortingSettings/SortingSetting';
import { SortingSettingHelper } from '../Helpers/SortingSettingHelper';

export class SortingSettingService {
  public FetchingReadySubject: BehaviorSubject<boolean>;
  public FetchingReady!: boolean;

  public sortingSettings!: SortingSetting[];

  public ValidatedReadySubject: BehaviorSubject<number>;
  public timesValidated: number = 0;
  public timesToValidate: number = 0;

  constructor() {
    this.FetchingReadySubject = new BehaviorSubject<boolean>(false);
    this.ValidatedReadySubject = new BehaviorSubject<number>(0);

    this.FetchItems();
  }

  public GetFetchStatus(): Observable<boolean> {
    this.FetchItems();
    return this.FetchingReadySubject;
  }

  private async FetchItems() {
    if (!this.FetchingReady) {
      this.FetchingReady = true;

      this.sortingSettings = await SortingSettingHelper.GetDetectionSettings();
      this.FetchingReadySubject.next(true);
    }
  }

  public SetCount(total: number) {
    this.timesToValidate  = total;
  }

  public AddCount() {
    this.timesValidated += 1;
    this.ValidatedReadySubject.next(this.timesValidated);
  }

  public GetCountStatus(): Observable<number> {
    return this.ValidatedReadySubject;
  }

  public async GetSortingSettings() {
    if (this.sortingSettings == undefined){
      this.sortingSettings = await SortingSettingHelper.GetDetectionSettings();
    }
    return this.sortingSettings;
  }

  public AddSortingSetting = async (newSetting: SortingSetting) => {
    var item = await SortingSettingHelper.AddItem(newSetting);

    if (!this.sortingSettings) {
      this.sortingSettings = [item];
    } else {
      this.sortingSettings.push(item);
    }

    return item;
  };

  public UpdateSortingSetting(item: SortingSetting) {
    var index = this.sortingSettings.findIndex((x) => x.id == item.id);
    if (index >= 0) {
      this.sortingSettings[index] = item;
      SortingSettingHelper.UpdateItem(item);
    }
  }

  public RemoveSortingSetting(item: SortingSetting) {
    var index = this.sortingSettings.findIndex((x) => x.id == item.id);
    if (index >= 0) {
      this.sortingSettings.splice(index, 1);
      SortingSettingHelper.DeleteItem(item);
    }

    return this.sortingSettings;
  }
}
