import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { take } from 'rxjs';
import { NewDetectionSetting } from './NewDetectionSetting';
import { DetectionSetting } from '../../Models/DetectionSettings/DetectionSetting';
import { RemoveButton } from '../../Components/Buttons/RemoveButton';

const { Option } = Select;

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

export function AddDetectionSettingModal(props: {
  open: boolean;
  existingConfigs: Array<DetectionSetting>;
  currentConfig?: NewDetectionSetting;
  onCancel: Function;
  onCreate: Function;
  onRemove: Function;
  onEdit: boolean;
}) {
  const { t, i18n } = useTranslation();

  const [form] = Form.useForm();

  const [removeItem, setRemoveItem] = useState(false);
  const [currentConfig, setCurrentConfig] = useState<NewDetectionSetting>();

  const [takeOver, setTakeOver] = useState('new');

  const options = [
    { label: t('new'), value: 'new' },
    { label: t('takefrom'), value: 'takefrom' },
  ];

  useEffect(() => {
    form.resetFields();
    setCurrentConfig(props.currentConfig);
    setRemoveItem(false);
  }, [form, props.currentConfig, currentConfig]);

  const onChangeSwitch = ({ target: { value } }: RadioChangeEvent) => {
    setTakeOver(value);
  };

  const TakeFromSelected = () => {
    if (takeOver == 'new') {
      return false;
    }
    return true;
  };

  const CreateNewConfig = (newData: NewDetectionSetting) => {
    props.onCreate(newData);
  };

  const onFinishForm = (values: NewDetectionSetting) => {
    if (removeItem) {
      props.onRemove(props.currentConfig);
      return;
    }

    CreateNewConfig(values);
  };

  const GetAvailableConfigs = () => {
    return props.existingConfigs.map((x) => {
      return {
        value: x.nameSetting,
        label: x.nameSetting,
      };
    });
  };

  const checkLabelExist = (_: any, value: string) => {
    var foundItem = props.existingConfigs?.find((x) => x.nameSetting === value);
    if (foundItem !== undefined) {
      if (foundItem.nameSetting === props.currentConfig?.nameSetting) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Dit label bestaat al'));
    }
    return Promise.resolve();
  };

  const GetTitle = () => {
    if (!props.onEdit) {
      return t('createnewconfig');
    }
    return t('editconfig');
  };

  const GetOkText = () => {
    if (!props.onEdit) {
      return t('create');
    }
    return t('safe');
  };

  return (
    <Modal
      title={GetTitle()}
      open={props.open}
      cancelText={t('cancel')}
      onOk={() => form.submit()}
      onCancel={() => props.onCancel()}
      footer={[
        <Button key="back" onClick={() => props.onCancel()}>
          {t('cancel')}
        </Button>,
        <>
          {props.onEdit && (
            <RemoveButton
              bindedFunction={setRemoveItem}
              bindedForm={form}
              disabled={false}
              textBtn={t('remove')}
            ></RemoveButton>
          )}
        </>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          {GetOkText()}
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={props.currentConfig}
        onFinish={onFinishForm}
        autoComplete="off"
      >
        <Form.Item<string>
          label={t('name')}
          name="nameSetting"
          rules={[
            { required: true, message: t('enterinputfield').toString() },
            { validator: checkLabelExist },
          ]}
        >
          <Input />
        </Form.Item>

        {!props.onEdit && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: 20,
                marginLeft: 150,
              }}
            >
              <Radio.Group
                options={options}
                onChange={onChangeSwitch}
                value={takeOver}
                optionType="button"
                buttonStyle="solid"
              />
            </div>

            {TakeFromSelected() && (
              <Form.Item
                label={t('existingTypes')}
                name="takeFrom"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={t('selectanoption')}
                  allowClear
                  options={GetAvailableConfigs()}
                ></Select>
              </Form.Item>
            )}
          </>
        )}
      </Form>
    </Modal>
  );
}
