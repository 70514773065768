import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Stage, Layer, Rect } from 'react-konva';

import { Space, Layout, Form, theme, Image, Button, Select } from 'antd';

interface SquareConfig {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  key?: number;
}

const CustomDrawer = () => {
  const [annotations, setAnnotations] = useState<SquareConfig[]>([]);
  const [newAnnotation, setNewAnnotation] = useState<SquareConfig[]>([]);

  const handleMouseDown = (event: any) => {
    if (newAnnotation.length === 0) {
      const { x, y } = event.target.getStage().getPointerPosition();
      setNewAnnotation([{ x, y, width: 0, height: 0, key: 0 }]);
    }
  };

  const handleMouseUp = (event: any) => {
    setAnnotations([]);
    setNewAnnotation([]);

    if (newAnnotation.length === 1) {
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;
      const { x, y } = event.target.getStage().getPointerPosition();
      if (sx == undefined || sy == undefined) {
        return;
      }
      const annotationToAdd = {
        x: sx,
        y: sy,
        width: x - sx,
        height: y - sy,
        key: annotations.length + 1,
      };
      annotations[0] = annotationToAdd;
      setNewAnnotation([]);
      setAnnotations(annotations);
    }
  };

  const handleMouseMove = (event: any) => {
    if (annotations.length > 1) {
      setAnnotations([]);
    }
    setNewAnnotation([]);

    if (newAnnotation.length === 1) {
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;
      const { x, y } = event.target.getStage().getPointerPosition();
      if (sx == undefined || sy == undefined) {
        return;
      }
      setNewAnnotation([
        {
          x: sx,
          y: sy,
          width: x - sx,
          height: y - sy,
          key: 0,
        },
      ]);
    }
  };

  const annotationsToDraw = [...annotations, ...newAnnotation];
  return (
    <Stage
      style={{
        backgroundImage:
          "url('https://storage.cloudkwekerijbloemendaal.com/Images/1.png'",
        backgroundSize: '230px',
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      width={250}
      height={275}
    >
      <Layer>
        {annotationsToDraw.map((value) => {
          return (
            <Rect
              x={value.x}
              y={value.y}
              width={value.width}
              height={value.height}
              fill="transparent"
              stroke="red"
            />
          );
        })}
      </Layer>
    </Stage>
  );
};

export default CustomDrawer;
