import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { fakeAuthProvider } from '../auth';
import { AuthData } from '../Models/Auth/AuthData';
import { authService, serviceBundler } from '../App';
import { UserModelSalt } from '../Models/Auth/UserModelSalt';
import { AuthHelper } from '../Helpers/AuthHelper';

interface AuthContextType {
  isLoggedIn: () => boolean;
  signin: (authData: UserModelSalt, callback: Function) => void;
  signout: (callback: Function) => void;
}

export let AuthContext = React.createContext<AuthContextType>(null!);

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  // if(!window.location.href.includes("cloudkwekerijbloemendaal")){
  //   fakeAuthProvider.isAuthenticated = true;
  //   return children;
  // }

  if (!auth.isLoggedIn()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let signin = (authData: UserModelSalt, callback: Function) => {
    return fakeAuthProvider.signin(authData, () => {
      if (fakeAuthProvider.isAuthenticated) {
        authService.SetCurrentEmail(authData.email);
        authService.SafeToken(
          fakeAuthProvider.authResultFromServer.token,
          fakeAuthProvider.authResultFromServer.expiresIn,
        );

        authService.SetLoggedIn(true);
      }

      callback(fakeAuthProvider.authResultFromServer);
    });
  };

  let isLoggedIn = (): boolean => {
    return authService.IsLoggedIn();
  };

  let signout = (callback: Function) => {
    return fakeAuthProvider.signout(() => {
      authService.SetCurrentEmail(null);
      callback();
    });
  };

  let value = { isLoggedIn, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export {};
