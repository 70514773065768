import { LanguageService } from './LanguageService';
import { ThemeService } from './ThemeService';
import { UserService } from './UserService';
import { TabService } from './TabService';
import { SettingsSystemService } from './SettingsSystemService';
import { DetectionSettingService } from './DetectionSettingService';
import { CameraSettingService } from './CameraSettingService';
import { SortingSettingService } from './SortingSettingService';

class ServiceBundler {
  public themeService;
  public languageService;
  public userService;
  public tabService;
  public settingsSystem;
  public cameraSettingService;
  public detectionSettingService;
  public sortingSettingService;

  constructor() {
    this.themeService = new ThemeService();

    this.languageService = new LanguageService();
    this.userService = new UserService();
    this.tabService = new TabService();

    this.settingsSystem = new SettingsSystemService();
    this.cameraSettingService = new CameraSettingService();
    this.detectionSettingService = new DetectionSettingService();
    this.sortingSettingService = new SortingSettingService();
  }
}

export { ServiceBundler };
