import style from './Loading.module.css';

import imageHeader from '../../Assets/headColor.png';

export const LoadingPage = () => {
  return (
    <div className={style.block}>
      <div className={style.centerLoading}>
        <div >
          <img width={70} src={imageHeader}></img>
        </div>
        <div className={style.ldsellipsisPosition}>
          <div className={style.ldsellipsis}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};
