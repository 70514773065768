import { VersionInfoApi } from "../Models/VersionInfo/VersionInfoApi";
import { HttpHelper } from "./HttpHelper";

export abstract class VersionInfoHelper {
  private static baseUrl = HttpHelper.GetUrlBaseApi() + 'api/' + 'VersionInfo';
  public static async GetVersionInfo() {
    var urlForRequest = this.baseUrl;
    return await HttpHelper.GetRequestForApi<VersionInfoApi>(urlForRequest);
  }
}
