import { WebSocketMessageType } from './WebSocketMessageType';
import { DetectionSetting } from '../DetectionSettings/DetectionSetting';
import { MessageWebSocket } from './MessageWebSocket';

export class SelectDetectionSetting extends MessageWebSocket {
  public Setting?: DetectionSetting

  constructor(setting: DetectionSetting) {
    super();
    this.Type = WebSocketMessageType.SelectDetectionSetting;
    this.Setting = setting;
  }
}
