import { BehaviorSubject, Observable } from 'rxjs';
import { DetectionSetting } from '../Models/DetectionSettings/DetectionSetting';
import { DetectionSettingHelper } from '../Helpers/DetectionSettingHelper';

export class DetectionSettingService {
  public FetchingReadySubject: BehaviorSubject<boolean>;
  public FetchingReady!: boolean;

  public detectionSettings!: DetectionSetting[];
  public detectionSetting!: DetectionSetting | null;

  constructor() {
    this.FetchingReadySubject = new BehaviorSubject<boolean>(false);

    this.FetchItems();
  }

  public GetFetchStatus(): Observable<boolean> {
    this.FetchItems();
    return this.FetchingReadySubject;
  }

  private async FetchItems() {
    if (!this.FetchingReady) {
      this.FetchingReady = true;

      this.detectionSettings =
        await DetectionSettingHelper.GetDetectionSettings();
      this.FetchingReadySubject.next(true);
    }
  }

  public SetCurrentSetting(setting: DetectionSetting) {
    this.detectionSetting = setting;
  }

  public GetCurrentSetting() {
    return this.detectionSetting;
  }

  public async GetDetectionSettings() {
    if (this.detectionSettings == undefined){
      this.detectionSettings = await DetectionSettingHelper.GetDetectionSettings();
    }
    return this.detectionSettings;
  }

  public AddDetectionSetting = async (newSetting: DetectionSetting) => {
    var item = await DetectionSettingHelper.AddItem(newSetting);

    if (!this.detectionSettings) {
      this.detectionSettings = [item];
    } else {
      this.detectionSettings.push(item);
    }

    return item;
  };

  public UpdateDetectionSetting(item: DetectionSetting) {
    var index = this.detectionSettings.findIndex((x) => x.id == item.id);
    if (index >= 0) {
      this.detectionSettings[index] = item;
      DetectionSettingHelper.UpdateItem(item);
    }
  }

  public RemoveDetectionSetting(item: DetectionSetting) {
    var index = this.detectionSettings.findIndex((x) => x.id == item.id);
    if (index >= 0) {
      this.detectionSettings.splice(index, 1);
      DetectionSettingHelper.DeleteItem(item);
      this.detectionSetting = null;
    }

    return this.detectionSettings;
  }
}
