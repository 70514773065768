import React from 'react';
import { Button, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import {
    FileImageOutlined,
  } from '@ant-design/icons';

interface DataType {
  key: string;
  name: string;
  address: string;
  tags: string[];
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Naam',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Tijd',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Sortering',
    key: 'tags',
    dataIndex: 'tags',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          let color = tag.length > 5 ? 'geekblue' : 'green';
          if (tag === 'uitval') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: 'Afbeelding',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <Button icon={<FileImageOutlined />}>Zie afbeelding</Button>
      </Space>
    ),
  },
];

const data: DataType[] = [
  {
    key: 'Plant x',
    name: 'Plant x',
    address: 'New York No. 1 Lake Park',
    tags: ['Sortering 2'],
  },
  {
    key: 'Plant x',
    name: 'Plant x',
    address: 'London No. 1 Lake Park',
    tags: ['Sortering 1'],
  },
  {
    key: 'Plant x',
    name: 'Plant x',
    address: 'Sydney No. 1 Lake Park',
    tags: ['uitval'],
  },
];

export const ResultsSorting = () => {
  return <Table columns={columns} dataSource={data} />;
};
