import { BehaviorSubject, Observable } from 'rxjs';
import { Camera } from '../Models/CameraSettings/Camera';

export class SettingsSystemService {
  public FetchingReadySubject: BehaviorSubject<boolean>;
  public FetchingReady!: boolean;

  constructor() {
    this.FetchingReadySubject = new BehaviorSubject<boolean>(false);

    this.FetchItems();
  }

  public GetFetchStatus(): Observable<boolean> {
    this.FetchItems();
    return this.FetchingReadySubject;
  }

  private async FetchItems() {
    if (!this.FetchingReady) {
      this.FetchingReady = true;

      this.FetchingReadySubject.next(true);
    }
  }
}
