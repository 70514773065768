import {
  Avatar,
  Button,
  Dropdown,
  MenuProps,
  Space,
  Tooltip,
  message,
} from 'antd';
import {
  InfoCircleOutlined,
  FlagOutlined,
  UserOutlined,
  CodeOutlined,
  DatabaseOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, Navigate, Route } from 'react-router-dom';

import style from './AvatarDropdown.module.css';
import { useTranslation } from 'react-i18next';

const handleMenuClick: MenuProps['onClick'] = (e) => {};

const GetSizeAvatar = () => {};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const AvatarDropdown = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const [sizeAvatar, setSizeAvatar] = useState(32);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    ResizeAvatar();

    function handleResize() {
      ResizeAvatar();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const GetMenuOptions = () => {
    const items: MenuProps['items'] = [
      {
        key: '1',
        label: <Link to="/">{t('sorting')}</Link>,
        icon: <CodeOutlined />,
      },
      {
        key: '2',
        icon: <UserOutlined />,
        label: <Link to={"/" +t('profile').toString()}>{t('profile')}</Link>,
      },
      {
        label: <Link to="/languages">{t('translations')}</Link>,
        key: '3',
        icon: <FlagOutlined />,
      },
      {
        label: <Link to="/info">{t('info')}</Link>,
        key: '4',
        icon: <InfoCircleOutlined />,
      },
    ];

    return items;
  };

  const menuProps = {
    items: GetMenuOptions(),
    onClick: handleMenuClick,
  };

  const ResizeAvatar = () => {
    setWindowDimensions(getWindowDimensions());
  };

  return (
    <Space wrap>
      <Dropdown menu={menuProps}>
        <Button
          style={{ borderStyle: 'none', background: 'rgba(0,0,0,0.0)', top: 2 }}
        >
          <Avatar
            size={sizeAvatar}
            style={{ backgroundColor: '#C1D202' }}
            icon={<UserOutlined />}
          />
        </Button>
      </Dropdown>
    </Space>
  );
};

export default AvatarDropdown;
