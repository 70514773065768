import { BehaviorSubject, map, Observable, Observer, Subject } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { MessageWebSocket } from '../Models/WebSocket/MessageWebSocket';
import { LocalHelper } from '../Helpers/LocalHelper';
import { HttpHelper } from '../Helpers/HttpHelper';
import { IMessageSock } from '../Models/WebSocket/IMessageSock';

export interface MessageSock {
  source: string;
  content: string;
}

class WebSocketService {
  private subject?: AnonymousSubject<MessageEvent>;

  public isOpen: BehaviorSubject<boolean>;
  public messages: Subject<IMessageSock>;
  public messagesChancel: BehaviorSubject<any | null>;

  private connectedToHost = false;
  private ws: any;

  private connectionSuccesfully = false;

  constructor() {
    this.isOpen = new BehaviorSubject<boolean>(false);

    this.messages = new Subject<MessageSock>();
    this.messagesChancel = new BehaviorSubject<IMessageSock | null>(null);

   this.ConnectToEndpoint(this.GetEndpoint());
  }

  public GetEndpoint(): string {
    if (HttpHelper.IsRemoteHosted()) {
      return LocalHelper.GetUrlForSocketRemote();
    } else {
      return LocalHelper.GetUrlForSocketLocal();
    }
    return LocalHelper.GetUrlForSocketLocalHost();
  }

  public ConnectToEndpoint(endpoint: string) {
    var endpointWebsocket = this.GetEndpoint();

    this.messages = <Subject<IMessageSock>>this.connect(endpointWebsocket).pipe(
      map((response: MessageEvent) => {
        let data = JSON.parse(response.data);
        this.messagesChancel.next(data);
        return data;
      }),
    );
  }

  public connect(url: string): AnonymousSubject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
    }
    return this.subject;
  }

  private create(url: string): AnonymousSubject<MessageEvent> {
    this.ws = new WebSocket(url);
    let observable = new Observable((obs: Observer<MessageEvent>) => {
      this.ws.onmessage = obs.next.bind(obs);
      this.ws.onerror = obs.error.bind(obs);
      this.ws.onclose = obs.complete.bind(obs);
      return this.ws.close.bind(this.ws);
    });

    let observer = {
      error: () => {
        this.connectionSuccesfully = false;
      },
      complete: () => {},
      next: (data: Object) => {},
    };

    this.ws.addEventListener('open', (event: any) => {
      if (!this.connectedToHost) {
        this.isOpen.next(true);
        this.connectedToHost = true;
      }
    });

    return new AnonymousSubject<MessageEvent>(observer, observable);
  }

  public async sendMessage(message: any) {
    await this.ws.send(JSON.stringify(message));
  }

  public close() {
    this.ws.close();
  }
}

export { WebSocketService };
