import { useNavigate, useParams } from 'react-router-dom';
import { Space, Layout, Form, Button, Empty, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { SettingOutlined } from '@ant-design/icons';
import style from './CameraSettingsPage.module.css';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderBar from '../../Components/HeaderBar/HeaderBar';
import { CenterBlock } from '../../Components/Sorting/CenterBlock';
import { SortingMenuTypes } from '../../Components/Sorting/Enums/SortingMenuTypes';
import { serviceBundler } from '../../App';
import CustomDrawer from '../../Components/CustomDrawer/CustomDrawer';
import Title from 'antd/es/typography/Title';
import { Camera } from '../../Models/CameraSettings/Camera';
import { AddCameraModal } from './AddCameraModal';

import { PlusSquareOutlined, EditOutlined } from '@ant-design/icons';
import Meta from 'antd/es/card/Meta';
import ReactPlayer from 'react-player';

export const CameraSettingsPage = () => {
  const { SettingsTab } = useParams();
  const { i18n, t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const [cameras, setCameras] = useState<Camera[]>([]);
  const [camera, setCamera] = useState<Camera>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModelEditMode, setModelEditMode] = useState(false);
  const [dataSaved, setDataSaved] = useState(true);

  useEffect(() => {
    serviceBundler.tabService.SetCurrentTab(SortingMenuTypes.CameraSettingsTab);

    if (loading) {
      FetchItems();
    }
  }, [0]);

  const FetchItems = async () => {
    var fetchedCameras =
      await serviceBundler.cameraSettingService.GetCameraSettings();
    if (fetchedCameras !== undefined) {
      setCameras(fetchedCameras);
    }
    setLoading(false);
  };

  const CreateCameraSetting = () => {
    setCamera(new Camera());
    setIsModalOpen(true);
  };

  const EditCameraSetting = (cam: Camera) => {
    setCamera(cam);
    setModelEditMode(true);
    setIsModalOpen(true);
  };

  const CreateCameraSettingFeedback = async (setting: Camera) => {
    if (isModelEditMode) {
      return UpdateCameraConfig(setting);
    }

    var createdSetting =
      await serviceBundler.cameraSettingService.AddCameraSetting(setting);

    setIsModalOpen(false);
  };

  const UpdateCameraConfig = (item: Camera) => {
    serviceBundler.cameraSettingService.UpdateCameraSetting(item);

    setModelEditMode(false);
    setIsModalOpen(false);
  };

  const RemoveCameraSetting = () => {};

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
      <Layout>
        <HeaderBar></HeaderBar>
        <Content className={style.contentBlock}>
          <CenterBlock
            actualTab={SortingMenuTypes.CameraSettingsTab}
            content={
              <div>
                <div>
                  <AddCameraModal
                    open={isModalOpen}
                    allData={cameras}
                    data={camera}
                    onCancel={() => setIsModalOpen(false)}
                    onCreate={CreateCameraSettingFeedback}
                    onRemove={RemoveCameraSetting}
                    onEdit={isModelEditMode}
                  ></AddCameraModal>
                </div>

                {/* <img height={300} src={"http://192.168.178.200:5000/vidd"} alt="webcam" />
                <img height={300} src={"http://192.168.178.200:5000/vid"} alt="webcam" /> */}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        minWidth: 1000,
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginTop: 0,
                          marginRight: 20,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          {cameras.length == 0 && (
                            <div
                              style={{
                                marginTop: 30,
                                marginLeft: 30,
                                marginRight: 30,
                              }}
                            >
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                imageStyle={{ height: 60 }}
                                description={<span>{t('nocams')}</span>}
                              ></Empty>
                            </div>
                          )}
                          {cameras && (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginTop: 30,
                                }}
                              >
                                {cameras.map((item: Camera, i) => (
                                  <Card
                                    style={{ width: 300, marginRight: 30 }}
                                    cover={
                                      <img
                                        src={item.linkToHost}
                                        style={{
                                          cursor: 'pointer',
                                          minHeight: 200,
                                        }}
                                      />
                                      // <iframe
                                      //   id="iFrameExample"
                                      //   title="iFrame Example"
                                      //   src="http://localhost:3000/Potlijn"
                                      // ></iframe>
                                    }
                                    actions={[
                                      <>
                                        {/* <RequireAuth> */}
                                        <EditOutlined
                                          onClick={() =>
                                            EditCameraSetting(item)
                                          }
                                          key="edit"
                                        />
                                      </>,
                                    ]}
                                  >
                                    <Meta
                                      title={item.name}
                                      // description={
                                      //   item.description ? (
                                      //     item.description
                                      //   ) : (
                                      //     <>
                                      //       <br></br>
                                      //     </>
                                      //   )
                                      // }
                                    />
                                  </Card>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          marginTop: 25,
                        }}
                      >
                        <Button
                          onClick={CreateCameraSetting}
                          icon={<PlusSquareOutlined />}
                        >
                          {t('addcam')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          ></CenterBlock>
        </Content>
      </Layout>
    </Space>
  );
};
